module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nehalem - A Gatsby theme","short_name":"nehalem","start_url":"/","background_color":"#a4cbb8","theme_color":"#a4cbb8","display":"minimal-ui","icon":"/codebuild/output/src940694558/src/root101/content/assets/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d58bba271f1426c8e473ebe2935fede0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"content","store":true},{"name":"tags","store":true},{"name":"excerpt","store":true},{"name":"path","store":true}],"resolvers":{"MarkdownRemark":{}}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-37989339-2"},
    }]
